let inputFocus;

let dayInput;
let monthInput;
let yearInput;
let hourInput;
let minuteInput;
let periodInput;

let activePicker;

document.addEventListener("focus", (event) => {
  let e = event.target;

  if (e.classList !== undefined) {
    if (e.classList.contains("date-time-picker-input") || e.classList.contains("date-picker-input") || e.classList.contains("time-picker-input")) {
      inputFocus = event.target;
      document.querySelector(".picker-background").style.display = "block";
      document.body.style.overflow = "hidden";
    }

    if (e.classList.contains("date-time-picker-input")) {
      activePicker = "date-time";
      dayInput = document.querySelector(".date-time-picker .day-input");
      monthInput = document.querySelector(".date-time-picker .month-input");
      yearInput = document.querySelector(".date-time-picker .year-input");
      hourInput = document.querySelector(".date-time-picker .hour-input");
      minuteInput = document.querySelector(".date-time-picker .minute-input");
      periodInput = document.querySelector(".date-time-picker .period-input");

      if (inputFocus.value === "") {
        let currentDate = new Date();
        let hours = currentDate.getHours();
        dayInput.value = currentDate.getDate();
        monthInput.value = currentDate.getMonth() + 1;
        yearInput.value = currentDate.getFullYear();

        if (hours >= 13 || hours === 0) {
          hourInput.value = Math.abs(hours - 12);
        } else {
          hourInput.value = hours;
        }

        if (currentDate.getMinutes() < 10) {
          minuteInput.value = "0" + currentDate.getMinutes();
        } else {
          minuteInput.value = currentDate.getMinutes();
        }

        if (hours >= 12) {
          periodInput.value = "PM";
        } else {
          periodInput.value = "AM";
        }
      } else {
        let dateTimeArray = inputFocus.value.split(/[\/\s:]/);
        let month = dateTimeArray[0];
        let day = dateTimeArray[1];
        let year = dateTimeArray[2];
        let hour = dateTimeArray[3];
        let minute = dateTimeArray[4];
        let period = dateTimeArray[5].toUpperCase();

        monthInput.value = month;
        dayInput.value = day;
        yearInput.value = year;
        hourInput.value = hour;
        minuteInput.value = minute;
        periodInput.value = period;
      }

      document.querySelector(".date-time-picker").style.display = "grid";
    } else if (e.classList.contains("date-picker-input")) {
      activePicker = "date";
      dayInput = document.querySelector(".date-picker .day-input");
      monthInput = document.querySelector(".date-picker .month-input");
      yearInput = document.querySelector(".date-picker .year-input");

      if (inputFocus.value === "") {
        let currentDate = new Date();
        dayInput.value = currentDate.getDate();
        monthInput.value = currentDate.getMonth() + 1;
        yearInput.value = currentDate.getFullYear();
      } else {
        let dateArray = inputFocus.value.split("/");
        let month = dateArray[0];
        let day = dateArray[1];
        let year = dateArray[2];

        monthInput.value = month;
        dayInput.value = day;
        yearInput.value = year;
      }

      document.querySelector(".date-picker").style.display = "grid";
    } else if (e.classList.contains("time-picker-input")) {
      activePicker = "time";
      hourInput = document.querySelector(".time-picker .hour-input");
      minuteInput = document.querySelector(".time-picker .minute-input");
      periodInput = document.querySelector(".time-picker .period-input");

      if (inputFocus.value === "") {
        let currentDate = new Date();
        let hours = currentDate.getHours();

        if (hours >= 13 || hours === 0) {
          hourInput.value = Math.abs(hours - 12);
        } else {
          hourInput.value = hours;
        }

        if (currentDate.getMinutes() < 10) {
          minuteInput.value = "0" + currentDate.getMinutes();
        } else {
          minuteInput.value = currentDate.getMinutes();
        }

        if (hours >= 12) {
          periodInput.value = "PM";
        } else {
          periodInput.value = "AM";
        }
      } else {
        let timeArray = inputFocus.value.split(/[\/\s:]/);
        let hour = timeArray[0];
        let minute = timeArray[1];
        let period = timeArray[2].toUpperCase();

        hourInput.value = hour;
        minuteInput.value = minute;
        periodInput.value = period;
      }

      document.querySelector(".time-picker").style.display = "grid";
    }
  }
}, true);

document.addEventListener("click", (event) => {
  let e = event.target;

  if (e.classList.contains("picker-background")) {
    document.querySelector(".picker-background").style.display = "none";
    document.body.removeAttribute("style");
    document.querySelector("." + activePicker + "-picker").removeAttribute("style");
  }

  if (e.classList.contains("day-increase")) {
    if (parseInt(dayInput.value) >= 31) {
      dayInput.value = 1;
    } else {
      dayInput.value = parseInt(dayInput.value) + 1;
    }
  } else if (e.classList.contains("day-decrease")) {
    if (parseInt(dayInput.value) <= 1) {
      dayInput.value = 31;
    } else {
      dayInput.value = parseInt(dayInput.value) - 1;
    }
  } else if (e.classList.contains("month-increase")) {
    if (parseInt(monthInput.value) >= 12) {
      monthInput.value = 1;
    } else {
      monthInput.value = parseInt(monthInput.value) + 1;
    }
  } else if (e.classList.contains("month-decrease")) {
    if (parseInt(monthInput.value) <= 1) {
      monthInput.value = 12;
    } else {
      monthInput.value = parseInt(monthInput.value) - 1;
    }
  } else if (e.classList.contains("year-increase")) {
    yearInput.value = parseInt(yearInput.value) + 1;
  } else if (e.classList.contains("year-decrease")) {
    yearInput.value = parseInt(yearInput.value) - 1;
  } else if (e.classList.contains("hour-increase")) {
    if (parseInt(hourInput.value) >= 12) {
      hourInput.value = 1;
    } else {
      hourInput.value = parseInt(hourInput.value) + 1;
    }
  } else if (e.classList.contains("hour-decrease")) {
    if (parseInt(hourInput.value) <= 1) {
      hourInput.value = 12;
    } else {
      hourInput.value = parseInt(hourInput.value) - 1;
    }
  } else if (e.classList.contains("minute-increase")) {
    if (parseInt(minuteInput.value) >= 59) {
      minuteInput.value = 0;
    } else {
      minuteInput.value = parseInt(minuteInput.value) + 1;
    }
    if (parseInt(minuteInput.value) < 10) {
      minuteInput.value = "0" + minuteInput.value;
    }
  } else if (e.classList.contains("minute-decrease")) {
    if (parseInt(minuteInput.value) <= 0) {
      minuteInput.value = 59;
    } else {
      minuteInput.value = parseInt(minuteInput.value) - 1;
    }
    if (parseInt(minuteInput.value) < 10) {
      minuteInput.value = "0" + minuteInput.value;
    }
  } else if (e.classList.contains("period-increase")) {
    if (periodInput.value === "AM") {
      periodInput.value = "PM";
    } else {
      periodInput.value = "AM";
    }
  } else if (e.classList.contains("period-decrease")) {
    if (periodInput.value === "AM") {
      periodInput.value = "PM";
    } else {
      periodInput.value = "AM";
    }
  }

  if (e.classList.contains("picker__confirm-button") || e.classList.contains("picker__confirm-button--container")) {
    if (activePicker === "date-time") {
      inputFocus.value = monthInput.value + "/" + dayInput.value + "/" + yearInput.value + " " + hourInput.value + ":" + minuteInput.value + " " + periodInput.value.toLowerCase();
      document.querySelector(".date-time-picker").removeAttribute("style");
    } else if (activePicker === "date") {
      inputFocus.value = monthInput.value + "/" + dayInput.value + "/" + yearInput.value;
      document.querySelector(".date-picker").removeAttribute("style");
    } else if (activePicker === "time") {
      inputFocus.value = hourInput.value + ":" + minuteInput.value + " " + periodInput.value.toLowerCase();
      document.querySelector(".time-picker").removeAttribute("style");
    }

    if (activePicker === "date-time" || activePicker === "date" || activePicker === "time") {
      document.querySelector(".picker-background").style.display = "none";
      document.body.removeAttribute("style");
    }

    annimationCheck();
  }
});