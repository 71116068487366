document.addEventListener("click", (event) => {
  let e = event.target;
  if (e.classList.contains("alert-msg__close")) {
    let getUrl = new URL(window.location.href);
    let params = new URLSearchParams(getUrl.search);
    if (params.has("message")) {
      params.delete("message");
      if (params.toString() != "") {
        history.replaceState(null, "", "?" + params.toString());
      } else {
        history.replaceState(null, "", "/");
      }
    }
    e.parentElement.classList.remove("alert-msg--show");
  }
});