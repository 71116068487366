let loginForm = document.querySelector(".login-form");
if (loginForm !== null) {
  loginForm.addEventListener("submit", (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    let options = {
      method: "POST",
      body: formData,
    };
    fetch("scripts/login.php", options).then((response) => {
      return response.json();
    }).then((text) => {
      let errorMessage = document.querySelector(".login-form .form-group--login__alert-invalid");
      if (text.loggedin === true) {
        errorMessage.style.display = "none";
        loginForm.reset();
        location.reload();
      } else {
        errorMessage.style.display = "block";
      }
    }).catch((error) => {
      console.error(error);
    })
  });
}