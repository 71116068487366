if (document.getElementById("side-nav-open") !== null) {

  let state;

  document.getElementById("side-nav-open").addEventListener("click", function () {
    document.body.style.overflowY = "hidden";
    document.querySelector(".side-nav").style.width = "200px";
    state = "open";
  });

  document.getElementById("side-nav-close").addEventListener("click", function () {
    document.querySelector(".side-nav").style.width = "0";
    state = "close";
  });

  document.querySelector(".side-nav").addEventListener("transitionend", function (e) {
    if (e.propertyName === "width" && state === "close") {
      if (document.body.style.overflowY === "auto" || document.body.style.overflowY === "") {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
      state = "open";
    }
  });
}

function expandSubMenu(e, height) {
  let menu = e.nextElementSibling;
  if (menu.clientHeight) {
    menu.style.minHeight = 0;
    menu.style.marginTop = 0;
  } else {
    menu.style.marginTop = "1em";
    menu.style.minHeight = height;
  }

  let caret = e.querySelector("span");

  if (caret.style.transform === "rotate(0deg)" || caret.style.transform === "") {
    e.querySelector("span").style.transform = "rotate(90deg)";
  } else {
    e.querySelector("span").style.transform = "rotate(0deg)";
  }
}