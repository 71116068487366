
class Tooltip extends HTMLElement {
  connectedCallback() {
    this.setup();
  }

  handleDropdownPosition() {
    const screenPadding = 16;
    const screenWidth = document.querySelector("html").clientWidth;

    const placeholderRect = this.placeholder.getBoundingClientRect();
    const dropdownRect = this.dropdown.getBoundingClientRect();

    const dropdownRightX = dropdownRect.x + dropdownRect.width;
    const placeholderRightX = placeholderRect.x + placeholderRect.width;

    if (dropdownRect.x < 0) {
      this.dropdown.style.left = "0";
      this.dropdown.style.right = "auto";
      this.dropdown.style.transform = `translateX(${-placeholderRect.x + screenPadding}px)`;
    } else if (dropdownRightX > screenWidth) {
      this.dropdown.style.left = "auto";
      this.dropdown.style.right = "0";
      this.dropdown.style.transform = `translateX(${(screenWidth - placeholderRightX) - screenPadding}px)`;
    }

    if (dropdownRect.y < 0) {
      this.dropdown.style.bottom = "auto";
      this.dropdown.style.top = 0;
      this.dropdown.style.transform = `translateY(${-placeholderRect.y + screenPadding}px)`;
    }
  }

  toggle() {
    if (this.classList.contains("tooltip--open")) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this.classList.add("tooltip--open");
    this.handleDropdownPosition();
  }

  close() {
    this.classList.remove("tooltip--open");
  }

  setup() {
    this.placeholder = this.querySelector(".tooltip__label");
    this.dropdown = this.querySelector(".tooltip__popout");

    this.placeholder.addEventListener("mouseover", () => this.handleDropdownPosition(), {passive: true});
    this.placeholder.addEventListener("touchstart", () => this.toggle(), {passive: true});
  }
}

function dismissAllTooltips(event) {
  if (typeof event.target.closest !== "function") return;
  const currentTooltip = event.target.closest("custom-tooltip");

  document.querySelectorAll(".tooltip--open").forEach(tooltip => {
    if (tooltip === currentTooltip) return;

    tooltip.classList.remove("tooltip--open");
  });
}

customElements.define("custom-tooltip", Tooltip);
document.addEventListener("touchstart", e => dismissAllTooltips(e), {passive: true});
