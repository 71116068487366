let dropDownLink = document.querySelector(".primary-link--user-dropdown");

if (dropDownLink !== null) {
  document.addEventListener("click", (event) => {
    let e = event.target;
    if (e.classList.contains("primary-link--user-dropdown")) {
      if (dropDownLink.nextElementSibling.classList.contains("user-dropdown--active")) {
        dropDownLink.nextElementSibling.classList.remove("user-dropdown--active");
        dropDownLink.firstElementChild.classList.remove("dropdown-tick--active");
      } else {
        dropDownLink.nextElementSibling.classList.add("user-dropdown--active");
        dropDownLink.firstElementChild.classList.add("dropdown-tick--active");
      }
    } else if (!e.classList.contains("user-dropdown--active")) {
      dropDownLink.nextElementSibling.classList.remove("user-dropdown--active");
      dropDownLink.firstElementChild.classList.remove("dropdown-tick--active");
    }
  });
}