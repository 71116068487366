class CustomFormValidation {
  constructor(formField, errorMessage) {
    this.formField = formField;
    this.errorMessage = errorMessage;
    this.isValid = false;
  }

  setMessage(message) {
    this.errorMessage.textContent = message;
  }

  setListMessage(message) {
    let ul = document.createElement("ul");
    ul.classList.add("form-group__msg--list");
    let li;
    message.forEach((item) => {
      li = document.createElement("li");
      li.textContent = item;
      ul.appendChild(li);
    });
    this.errorMessage.innerHTML = "";
    this.errorMessage.appendChild(ul);
  }

  get getListMessage() {
    return this.errorMessage.querySelectorAll("ul");
  }

  setMessageColor(color) {
    this.errorMessage.style.color = color;
  }

  setBorder(borderColor) {
    this.formField.style.borderColor = borderColor;
  }

  setBackgroundColor(backgroundColor) {
    this.formField.style.backgroundColor = backgroundColor;
  }

  checkField(pattern) {
    return pattern.test(this.formField.value);
  }

  setValid(state) {
    this.isValid = state;
  }

  get getFormField() {
    return this.formField;
  }

  get getValid() {
    return this.isValid;
  }
}

function firstNameCheck(fname) {
  if (fname.checkField(/^[A-Z][a-z]+$/)) {
    fname.setBorder("green");
    fname.setBackgroundColor("rgba(0,255,0,.25)");
    fname.setMessage("Valid");
    fname.setMessageColor("green");
    fname.setValid(true);
  } else if (fname.checkField(/^[a-z]+$/)) {
    fname.setBorder("red");
    fname.setBackgroundColor("rgba(255,0,0,.25)");
    fname.setMessage("Please capitalize the first letter in your name.");
    fname.setMessageColor("red");
    fname.setValid(false);
  } else if (fname.getFormField.value === "" && fname.getFormField.hasAttribute("required")) {
    fname.setBorder("red");
    fname.setBackgroundColor("rgba(255,0,0,.25)");
    fname.setMessage("This field is required.");
    fname.setMessageColor("red");
    fname.setValid(false);
  } else if (fname.getFormField.value === "" && !fname.getFormField.hasAttribute("required")) {
    fname.setBorder("initial");
    fname.setBackgroundColor("initial");
    fname.setMessage("");
    fname.setMessageColor("initial");
    fname.setValid(true);
  } else {
    fname.setBorder("red");
    fname.setBackgroundColor("rgba(255,0,0,.25)");
    fname.setMessage("Please type your first name.");
    fname.setMessageColor("red");
    fname.setValid(false);
  }
}

function lastNameCheck(lname) {
  if (lname.checkField(/^[A-Z][a-z]+$/)) {
    lname.setBorder("green");
    lname.setBackgroundColor("rgba(0,255,0,.25)");
    lname.setMessage("Valid");
    lname.setMessageColor("green");
    lname.setValid(true);
  } else if (lname.checkField(/^[a-z]+$/)) {
    lname.setBorder("red");
    lname.setBackgroundColor("rgba(255,0,0,.25)");
    lname.setMessage("Please capitalize the first letter in your name.");
    lname.setMessageColor("red");
    lname.setValid(false);
  } else if (lname.getFormField.value === "" && lname.getFormField.hasAttribute("required")) {
    lname.setBorder("red");
    lname.setBackgroundColor("rgba(255,0,0,.25)");
    lname.setMessage("This field is required.");
    lname.setMessageColor("red");
    lname.setValid(false);
  } else if (lname.getFormField.value === "" && !lname.getFormField.hasAttribute("required")) {
    lname.setBorder("initial");
    lname.setBackgroundColor("initial");
    lname.setMessage("");
    lname.setMessageColor("initial");
    lname.setValid(true);
  } else {
    lname.setBorder("red");
    lname.setBackgroundColor("rgba(255,0,0,.25)");
    lname.setMessage("Please type your last name.");
    lname.setMessageColor("red");
    lname.setValid(false);
  }
}

function emailCheck(email) {
  if (email.checkField(/^[A-z0-9._%+-]{1,64}@(?:[A-z0-9-]{1,63}\.){1,125}[A-z]{2,63}$/)) {
    email.setBorder("green");
    email.setBackgroundColor("rgba(0,255,0,.25)");
    email.setMessage("Valid");
    email.setMessageColor("green");
    email.setValid(true);
  } else if (email.getFormField.value === "" && email.getFormField.hasAttribute("required")) {
    email.setBorder("red");
    email.setBackgroundColor("rgba(255,0,0,.25)");
    email.setMessage("This field is required.");
    email.setMessageColor("red");
    email.setValid(false);
  } else if (email.getFormField.value === "" && !email.getFormField.hasAttribute("required")) {
    email.setBorder("initial");
    email.setBackgroundColor("initial");
    email.setMessage("");
    email.setMessageColor("initial");
    email.setValid(true);
  } else {
    email.setBorder("red");
    email.setBackgroundColor("rgba(255,0,0,.25)");
    email.setMessage("Please type a valid email address.");
    email.setMessageColor("red");
    email.setValid(false);
  }
}

function addressCheck(address) {

  const regexp = /(?:[A-Z][a-z]+)|(?<lower>[a-z]+)/g;
  const str = address.getFormField.value;
  const matches = str.matchAll(regexp);
  let lower = false;

  for (const match of matches) {
    if (match.groups.lower) {
      lower = true;
      break;
    }
  }

  if (address.checkField(/^[0-9]+\s([A-Z]{1}[a-z]+\s)+([A-Z]{1}[a-z]+)$/)) {
    address.setBorder("green");
    address.setBackgroundColor("rgba(0,255,0,.25)");
    address.setMessage("Valid");
    address.setMessageColor("green");
    address.setValid(true);
  } else if (address.checkField(/^[0-9]+\s/g) && lower) {
    address.setBorder("red");
    address.setBackgroundColor("rgba(255,0,0,.25)");
    address.setMessage("Please capitalize the first letter in each word.");
    address.setMessageColor("red");
    address.setValid(false);
  } else if (address.getFormField.value === "" && address.getFormField.hasAttribute("required")) {
    address.setBorder("red");
    address.setBackgroundColor("rgba(255,0,0,.25)");
    address.setMessage("This field is required.");
    address.setMessageColor("red");
    address.setValid(false);
  } else if (address.getFormField.value === "" && !address.getFormField.hasAttribute("required")) {
    address.setBorder("initial");
    address.setBackgroundColor("initial");
    address.setMessage("");
    address.setMessageColor("initial");
    address.setValid(true);
  } else {
    address.setBorder("red");
    address.setBackgroundColor("rgba(255,0,0,.25)");
    address.setMessage("Please type a valid street address.");
    address.setMessageColor("red");
    address.setValid(false);
  }
}

let filter = ["Backspace", "Tab", "ArrowRight", "ArrowLeft", "Delete"];
for (let i = 0; i <= 9; i++) {
  filter.push(i.toString());
}

function enforceFormat(event) {
  if (!filter.includes(event.key)) {
    event.preventDefault();
  }
}

function formatPhoneText(event) {
  let target = event.target;
  let input = target.value.replace(/\D/g, "").substring(0, 10);
  let areaCode = input.substring(0, 3);
  let middle = input.substring(3, 6);
  let last = input.substring(6, 10);

  if (input.length > 6) {
    target.value = `${areaCode}-${middle}-${last}`;
  } else if (input.length > 3) {
    target.value = `${areaCode}-${middle}`;
  } else if (input.length > 0) {
    target.value = `${areaCode}`;
  }
}

function numberCheck(number) {
  if (number.checkField(/^\d{3}-\d{3}-\d{4}$/)) {
    number.setBorder("green");
    number.setBackgroundColor("rgba(0,255,0,.25)");
    number.setMessage("Valid");
    number.setMessageColor("green");
    number.setValid(true);
  } else if (number.getFormField.value === "" && number.getFormField.hasAttribute("required")) {
    number.setBorder("red");
    number.setBackgroundColor("rgba(255,0,0,.25)");
    number.setMessage("This field is required.");
    number.setMessageColor("red");
    number.setValid(false);
  } else if (number.getFormField.value === "" && !number.getFormField.hasAttribute("required")) {
    number.setBorder("initial");
    number.setBackgroundColor("initial");
    number.setMessage("");
    number.setMessageColor("initial");
    number.setValid(true);
  } else {
    number.setBorder("red");
    number.setBackgroundColor("rgba(255,0,0,.25)");
    number.setMessage("Please type a valid phone number in ###-###-#### format");
    number.setMessageColor("red");
    number.setValid(false);
  }
}

function passwordCheck(password) {
  if (password.checkField(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!"#$%&"()\*+,\-\./:;<=>?@\[\\\]\^_`{|}~])(?!.*\s).{8,32}$/)) {
    password.setBorder("green");
    password.setBackgroundColor("rgba(0,255,0,.25)");
    password.setMessage("Valid");
    password.setMessageColor("green");
    password.setValid(true);
  } else if (password.getFormField.value === "" && password.getFormField.hasAttribute("required")) {
    password.setBorder("red");
    password.setBackgroundColor("rgba(255,0,0,.25)");
    password.setMessage("This field is required.");
    password.setMessageColor("red");
    password.setValid(false);
  } else if (password.getFormField.value === "" && !password.getFormField.hasAttribute("required")) {
    password.setBorder("initial");
    password.setBackgroundColor("initial");
    password.setMessage("");
    password.setMessageColor("initial");
    password.setValid(true);
  } else {
    password.setBorder("red");
    password.setBackgroundColor("rgba(255,0,0,.25)");
    if (password.getListMessage.length < 1)
      password.setListMessage([
        "At least one number.",
        "At least one lower case letter.",
        "At least one upper case letter.",
        "At least one special character.",
        "Must be 8 to 32 characters long."
      ]);
    password.setMessageColor("red");
    password.setValid(false);
  }
}